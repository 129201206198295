a:link {
  text-decoration: none;
}
.AppBar {
  opacity: 1;
  overflow: hidden;
}

.AppBar.shrink {
  transition: all 1s cubic-bezier(0.24, 0.63, 0.5, 0.99);
}

.logo {
  width: 80px;
  height: 70px;
  transition: all 1s cubic-bezier(0.24, 0.63, 0.5, 0.99);
  margin-left: 1.5rem;
}
/* 
.logo.shrink the same level 
*/

.logo.shrink {
  width: 60px;
  height: 50px;
}

.fontSize {
  font-size: 18px;
  transition: all 0.8s cubic-bezier(0.24, 0.63, 0.5, 0.99);
}

.fontSize.shrink {
  font-size: 14px;
}

.nav ul li {
  text-align: left;
}

.nav .nav__menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 1.5rem;
}

.nav .nav__menu li{
  width: 10rem;
}

.nav .nav__menu li > *{
  cursor: pointer;
}

.highlight  {
  color: #FFAB51 !important ;
}

.nav__menu{
  /* display: grid;
  grid-template-columns: 20% 1fr;
  align-content: center;
  background-color: green; */
}

/* stroke */
nav.stroke ul li a,
nav.fill ul li a {
  position: relative;
}
nav.stroke ul li a:after,
nav.fill ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #aaa;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}

nav.fill ul li a {
  transition: all 2s;
}

nav.fill ul li a:after {
  text-align: left;
  content: '.';
  margin: 0;
  opacity: 0;
}

nav.fill ul li a:hover {
  color: #fff;
  z-index: 1;
}
nav.fill ul li a:hover:after {
  z-index: -10;
  animation: fill 1s forwards;
  -webkit-animation: fill 1s forwards;
  -moz-animation: fill 1s forwards;
  opacity: 1;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: -8px;
  left: 0;
  background-color: #f7334d;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}


@media (max-width: 40rem) {
  .logo {
    width: 60px;
    height: 50px;
    transition: all 0.8s cubic-bezier(0.24, 0.63, 0.5, 0.99);
  }

  .logo.shrink {
    width: 50px;
    height: 40px;
  }

  .NavigationBar {
    display: none;
  }

}

@media (min-width: 40rem) {
  .DrawerNav {
    display: none;
  }
}
