.FeatureWork-Container {
    margin: 6rem auto;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    h1 {
        margin-bottom: 3rem;
        text-transform: uppercase;
    }
    line-height: 1.3rem;
    width: 100%;
}

@media (max-width:40rem) {
    .FeatureWork-Container {
        width: 75%;
    }
}