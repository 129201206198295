.Footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#151A26;
  color: white;
  padding: 15px 50px;
  margin-top: auto;
}

.Footer::before {
  content: ' ';
  flex: 1;
}

/* .Footer::after */

.Copyright {
  flex: 1;
  text-align: center;
  font-size: 14px;
}

.FooterSocial {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

/* + => next sibiling */
.FooterSocial > a + a {
  margin-left: 30px;
}

@media all and (max-width:40rem) {

  .Footer {
    display: flex;
    flex-direction: column;
    padding: 15px 50px;
  }

  .Copyright {
    font-size: 9px;
    margin-bottom: 15px;
  }

  .socailIcon {
    font-size: 0.7rem;
  }  
}
