.uiuxGrid {
    width: 25rem;
    height: 100%;
    padding: 1rem 1rem;
}

.uiuxlayout {
    width: 100%;
    height: 23rem;
}

.Introuiux {
    margin-top: 1.5rem;
}

.name {
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;
    margin-bottom: 0.7rem;
}

.Title {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    margin-bottom: 0.7rem;
    font-size: 14px;
}

.description {
    line-height: 1.3rem;
}

@media (max-width:40rem) {
   
    .uiuxGrid {
        width: 21rem;
    }

    .uiuxlayout {
        height: 19rem;
    }
}

