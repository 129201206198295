.AboutMeDiv {
    background-color: rgb(222, 249, 255)
}

.About-Title {
    margin-bottom: 1.5rem;
    width: 100%;
}

.About-Title h2{
    line-height: 2.5rem;
}

.About-Title > p{
    line-height: 1.5rem;
}

.AboutMe {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    padding:3rem 5rem;
}

.Avatar-Frame {

}

.AboutMe .fontBlod {
    font-weight: bold;
}

.About-Avatar {
   flex: 1;
   width: 25%;
//    background-color:purple;
}

.About-Intro {
    flex: 2;
    text-align: left;
    width: 75%;
    // background-color: aqua;
}

.About-Intro h2 {
    font-weight: bold;
}

.About-Skill {
    margin-top: 1.5rem;
}

.About-Skill > div{
    margin-top: 1rem;
}

.About-Skill > div > div {
    width: 100px;
    margin: 0 auto;
}

.About-Skill > div > p {
    flex: 1;
    line-height: 1.5rem;
}

.About-Skill .fontBlod{
    font-weight: bold;
}

.About-Skill-pic-code {
    /* padding-left: 5px; */
    /* position: relative;
    top: -8px; */
}

.About-Skill-pic {
    position: relative;
    top: -8px;
}

.About-Edu-pic {
    /* position: relative;
    top: -8px; */
}

.About-Certificate-pic {
    /* position: relative;
    top: -8px; */
}

.About-UX {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.About-Code {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.About-Edu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.About-Certificate {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.SocialIcon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;
}

.SocialIcon a {
    margin: 0px 10px;
}

@media (max-width: 40rem) {

    .About-Avatar {
        width: 100%;
    }

    .About-Intro {
        text-align: center;
        width: 100%;
    }

    .AboutMe {
        display: inline-block;
        width: 100%;
    }

    .AboutMe h1{
        font-size: 28px;
    }

    .About-Intro h1 {
        font-size: 28px;
        text-align: center;
    }
    
    .About-UX {
        margin-top: 1rem;
        flex-direction: column;
    }

    .About-Code { 
        flex-direction: column;
    }

    .About-Edu {
        flex-direction: column;
    }
    
    .About-Certificate {
        flex-direction: column;
    }

    .SocialIcon {
        margin-bottom: 1.3rem;
    }
}

