/*
  Slide animation styles

  You may need to add vendor prefixes for transform depending on your desired browser support.
*/

$color-blue: #151A26;
$color-blue-dark: #151A26;
$submenu-width: 100%;
$submenu-height: 24px;

.slide-enter {
  transform: translateY(-100%);
  transition: .3s cubic-bezier(0, 1, 0.5, 1);

  &.slide-enter-active {
    transform: translateY(0%);
  }
}

.slide-leave {
  transform: translateY(0%);
  transition: .3s ease-in-out;

  &.slide-leave-active {
    transform: translateY(-100%);
  }
}

/*
  CSS for the submenu container, needed to adjust the behaviour to our needs.
  Try commenting out this part to see how the animation looks without the container involved.
*/

.submenu-container {
  height: 250px; // this value can be higher, but needs to be set
  min-width: $submenu-width;
  font-size: $submenu-height;
  position: absolute;
  overflow: hidden;
  z-index: -1;  
}


/*
  Layout styles.

  I like to work on stuff that's good looking so I remixed a cool simple menu by Mike Rojas (thanks!): https://codepen.io/mikerojas87/pen/rojKb 
*/

// html {
//   box-sizing: border-box;
// }

// *, *:before, *:after {
//   box-sizing: border-box;
// }

// body {
//   margin: 0;
//   font-family: 'Open Sans', sans-serif;
//   font-size: 16px;
//   line-height: 1.5;
// }

.menu-container {
  text-align: center;
}

nav {
  ul {
    list-style: none;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 0;
    text-align: center;
  }
}

.nav {
  display: inline-block;
  margin: 2rem auto 0;
  background: $color-blue-dark;
  text-align: center;
  a {
    display: block;
    padding: 0 16px;
    line-height: inherit;
    color: #fff;
    cursor: default;
    text-align: center;
  }

  a:hover {
    opacity: 0.7;
  }

}
  
.nav__menu {
  line-height: 45px;
  font-weight: 700;
  text-transform: none;
}

.nav__menu li {
   width: 100%;
}

.nav__menu-item {
  display: inline-block; 
  position: relative;
  &:hover {
    background-color: $color-blue;
    .nav__submenu {
      // display: block;
      height: auto;
      visibility: visible;
      top:35px;
    }
  }
}
    
.nav__submenu {
  font-weight: 300;
  text-transform: none;
  // display: none;
  position: absolute;
  width: $submenu-width;
  background-color: $color-blue;
  text-align: left;
  visibility: hidden;
  height: 0px;
  top:30px;
  padding-top: 10px;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
  
}
    
.nav__submenu-item {
  
  &:hover {
    background: rgba(#000, 0.1);
  }
}


.fontNavSize{
  font-size: 22px;
  transition: all 0.8s cubic-bezier(0.24, 0.63, 0.5, 0.99);
}

.fontNavSize.shrink {
  font-size: 16px;
}
