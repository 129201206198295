.IHelpIntro {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center; */
}

.IHelpIntro img {
    display: block;
    margin: 10rem auto 1rem auto; 
    width: 80%;
    height: 100%;
}


@media (max-width:40rem) {
    .IHelpIntro img {
        width: 90%;
    }
}
