.hero {
    padding: 0;
    margin: 0;
    position: relative;
    /* background: linear-gradient(to bottom,#f8f8ff,#c0c0c0); 
    ,#f8f8ffe6,#c0c0c000

    linear-gradient(to bottom,#FFFFFF,#c0c0c000)
    */
    /* background: url("./images/me.jpg"); */
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) ), url("./images/me.jpg");
    height: 100vh;
    width: 100%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    overflow: hidden;
}
/* --mywidth: 100%;
width: calc((var(--mywidth) * 75%); */

.heroPage {
    background-size:cover;
    background-position: top;
}

.herointro{
    position: absolute;
    top: 85%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%); 
    width: 60%;
}

.herointro h1 {
    font-family: 'Montserrat', sans-serif;
    color: white;
    /* font-size: 40px; */
    line-height: 2.5rem;
    margin: 0.7rem 0;
}

.herointro p {
    color: white;
    font-size: 22px;
    font-weight: 300;
}

@media (max-width:40em) {   

    .herointro {
        width: 80%;
    }

    .herointro h1{
        font-size: 17px;
        line-height: 1.3rem;
    }
    .herointro p{
        font-size: 14px;
    }
}