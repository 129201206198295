.CoffeeResearchIntro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CoffeeResearchIntro img {
    width: 80%;
    height: 80%;
    display: block;
    margin: 10rem auto 1rem auto; 
}

@media (max-width: 40rem) {
    .CoffeeResearchIntro img  {
        width: 90%;
    }
}