.layout-container {
  //display: flex;
  //flex-direction: column;
  //min-height: 100%;
  min-height: 100%;
}

@media (min-width: 768px) {
  .layout-container {
    height: 100%;
  }
}
