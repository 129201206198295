.featureworkcontainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(30rem,auto));
  justify-items: center;
  margin: 10rem auto;
}

@media (max-width:40rem) {
  .featureworkcontainer {
    gap: 8rem;
    margin: 5rem 0;
    grid-template-columns: 1fr;
  }
}