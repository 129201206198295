.section-divider {
    width: 100%;
    height: 600px;
    margin: 6rem auto;
    line-height: 1.3rem;

    h1 {
        margin-bottom: 3rem;
        text-transform: uppercase;
    }

    .section-divider-img {
        width: 20rem;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 40rem) {
    .section-divider {
        width: 80%;
        margin-bottom: 0rem;
        .section-divider-img {
            width: 13rem;
        }
    }
}
