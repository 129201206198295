.uiuxoverall {
    /* height: 100vh; */
    margin-top: 8rem;
    /* margin:5% 0px 0px 0px; */
    
}

.uiuxIntro {
    /* height: 768px;
    width:100%;
    background: no-repeat center center url("../WorkContainer/pics/UIUX.png");
    background-attachment: fixed;
    background-size: cover;
    background-color: #464646; */
}

.uiuxIntro .introTitle{
    font-size: 36px;
    margin-bottom: 36px;
}

.uiuxIntro .introTagline{
    font-size: 24px;
}

@media only screen and (max-width: 40rem) {
    .uiuxIntro {
        /* height: 300px;
        width: 490px; */
    }
    .uiuxoverall {
       
    }
}