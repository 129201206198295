.AnimalVillage-intro-container {
    position: relative;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) ), url("./img/bg.jpg");
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.AnimalVillage-intro-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: white;
    text-align: center;
}

.AnimalVillage-Container {
    box-sizing: border-box;
    line-height: 1.5rem;
}

.Bold {
    font-weight: 700;
}

@media (max-width: 40rem) {
    .AnimalVillage-Container {
        margin-top: 0rem;
    }

    .AnimalVillage-intro-title {
        width: 75%;
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
