.layout {
    /* margin: 10rem auto 1rem auto;  */
}

.MMFContainer {
    /* padding: 10px; */
}

.Link {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
}

.MMFContainer .title {
    font-weight: bold;
    font-size: 2.5rem;
    text-align: center;
}

.MMFContainer img {
    width: 80%;
    /* height: 80%; */
    display: block;
    margin: 10rem auto 1rem auto; 
}

@media (max-width:65rem) {
    .Link {
        flex-wrap: wrap;
    }
}


@media (max-width: 40rem) {

    .Link {
        flex-wrap: wrap;
    }
    .MMFContainer img  {
        width: 90%;
    }
}

