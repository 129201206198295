.Intro {
    margin: 10rem 5rem 3rem 5rem; 
    font-size: 1rem;
    line-height: 1.5rem;
}

.StarbuckssIntro {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left; */
    /* margin:0px 20px;  */
}


/* .StarbuckssIntro .title{
    font-size: 28px;
    font-weight: bold;
} */

.StarbuckssIntro .first {
    /* margin:20px 0px;  */
}

.StarbucksCaseStudy > p {

}

.StarbuckssIntro .second {
    margin:2.5rem 0px; 
}

.second ul li {
    margin: 8px 0px;
}

.third {
    margin:50px 0px;
}

.startbuckimage {
    width: 50%;
}

.bottom {
    margin-bottom: 5rem;
}

.book img {
  display: block;
  margin: 0 auto;
}

.statbuckprototype {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.statbuckprototype-item {
    margin-bottom: 1rem 0;
    width: 80rem;
}

.hat img{
    width:50%;
}

.hat {
    /* display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; */
}

.videopIntro {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin-bottom:3rem;
}

.videopIntro .player-wrapper {
    margin-top: 0;
    margin-right: 3rem;
}

.summarycontainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.summarycontainer ol li {
    margin: 0 1rem 1rem 0;
}

.video-description p {
    margin: 0 0 1rem 0;
}

.summary-text {
    width:100%;
}

.summary-description {
    width:100%;
}


@media (max-width: 40rem) {

    .Intro {
        margin: 8rem 5rem 1.5rem 5rem; 
    }

    .StarbuckssIntro .title{
        font-size: 22px;
    }
    
    .startbuckimage {
       margin: 0;
    }

    .prototype-item-image {
        display: block;
        width: 100%;
    }
    .userflow {
        height: 100%;
    }
    .player-wrapper {
        justify-content: center;
        text-align: center;
    }
    .book img {
       width: 60%;
    }
    .hat img{
        width:100%;
    }

    .summarycontainer {
        flex-wrap: wrap;
    }

}

