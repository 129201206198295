.Section-layout {
    height: 500px;
    width: 100%;
    margin: 5rem auto;
}

.Section-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.Section-content {
    text-align: left;
    width: 60%;
    line-height: 1.3rem;
    h1 {
        margin-top:0;
    }
}

.Section-image-container {
    width: 40%;
}

.Section-image {
    width: 19rem;
    height: 19rem;
}

.moremarginBottom {
    margin-bottom: 3.5rem;
}


@media screen and (min-width: 40rem) and (max-width:1380px) {

    .Section-layout {
        width: 60rem;
        
    }

    .Section-container {
        
    }

    .Section-content {
        width: 25rem;
    }
}

@media (max-width: 40rem) {
    
    .Section-layout {
        margin-top: 1rem;
        margin-bottom: 12.5rem;
    }

    .Section-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 3rem;
    }

    .Section-content {
        text-align: center;
        width: 75%;
        h1 {
            margin-top:1rem;
        }
    }

    .Section-image {
        width: 13rem;
        height: 100%;
    }

}
