.FoodWasteIntro {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.FoodWasteIntro img {
    width: 80%;
    height: 80%;
    display: block;
    margin: 10rem auto 1rem auto; 
}

.videoplayer {
    margin: 15px auto 15px auto;
}

.linkContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-left: 150px;
}

.linkContainer > div {
    margin:20px;
}

@media (max-width: 40rem) {
    .videoplayer {
        margin: 0 auto;
    }
    .linkContainer {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0px;
    }
    .FoodWasteIntro img {
        width: 90%;
    }
}

@media (max-width:65rem) {
    .videopIntro {
        flex-wrap: wrap;
        width: 100%;
    }
    .linkContainer {
        flex-wrap: wrap;
        justify-content: center;
        margin-left: 0px;
    }
}

