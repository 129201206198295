.featurework-Grid {
    width: 23rem;
    height: 100%;
}

.featurework-layout {
    width: auto;
    height: 21rem;
    margin: 0 auto;
}

.feature-into {
    margin-top: 2rem;

    .feature-into-name {
        font-weight: bold;
        font-size: 2rem;
        text-align: left;
        margin-bottom: 0.7rem;
        line-height: 2.5rem;
    }

    .feature-into-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 300;
        margin-bottom: 0.7rem;
        font-size: 14px;
    }

    .feature-into-type {
        text-align: right;    
        width: 200px;
    }

    .description {
        text-align: left;
        line-height: 1.3rem;
    }
}

@media (max-width:40rem) {

    .featurework-Grid {
        width: 19rem;
    }

    .featurework-layout {
        width: auto;
        height: 17rem;
    }

    .feature-into {
        .feature-into-container {
            text-align: left;
        }

        .feature-into-name {
            margin-bottom: 1rem;
        }
    }
}

