.gamemeet-container {
    min-height: 100vh;
    margin: 10rem auto 1rem auto;
    h1 {
        text-align: center;
        margin-bottom: 3rem;
    }

    img {
        display: block;
        margin: 0 auto 3rem auto;
    }

    .gamemeet-intro {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 3rem;
    }
}

@media (max-width:40rem) {
    .gamemeet-container {
        margin: 5rem auto 1rem auto;
        padding: 3rem 3rem;
        .gamemeet-intro {
            line-height: 1.5rem;
        }
    }
}