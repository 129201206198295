.intro-container {
    margin: 10rem auto 5rem auto;
    box-sizing: border-box;
    width: 90rem;
    height: 35rem;
    position: relative;
}

/*
    Info
*/

.Info {
    background: url('./img/animalvillage_bg.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 2rem;
    width: 100%;
    height: 100%;
}

.overlay {
    width: 100%;
    height: 100%;
    border-radius: 2rem;
    background: #000;
    opacity: .3;
    position: absolute;
    top:0;
    left: 0;
}

.Info .Info_title {
    color: #ffffff;
    font-weight: bold;
    font-size: 3.5rem;
    position: absolute;
    top: 3rem;
    left: 3rem;
}

.Info .Info_intro {
    color:white;
    font-size: 1rem;
    font-weight: 300;
    position: absolute;
    top: 8.5rem;
    left: 3rem;
    width: 80rem;
    padding: 0 3rem 0 0;
    .Info_intro-text {
        line-height: 1.3rem;
    }
}

.marginBottom {
    margin-bottom: 2rem;
}

/*
    Linkbutton
*/

.Linkbutton {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
    height: 100px;
    margin-left:3rem;
    position: absolute;
    bottom: 2rem;
}

.Linkbutton a {
    margin-right:1.5rem;
}


/*
    Button
*/

.button_link {
    /* width: 15rem; */
}

.button_link a {
    border-radius: 5px;
    background: linear-gradient(to top, #033333, #45b5e9 95%);
    //background: #151A26;
    border: none;
    color: #FFFFFF;
    text-align: center;
    /* text-transform: uppercase; */
    font-size: 12px;
    font-weight: 500;
    padding: 1rem 1.5rem;
    transition: all 0.4s;
    cursor: pointer;
  }
  .button_link a span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
  }
  .button_link a span:after {
    content: '>';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  .button_link a:hover span {
    padding-right: 25px;
  }
  .button_link a:hover span:after {
    opacity: 1;
    right: 0;
}

.button_yellow a{
    border-radius: 5px;
    background: linear-gradient(to top, #814300, #FFAB51 90%);
    border: none;
    color: #FFFFFF;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    padding: 1rem 1.5rem;
    width: 15rem;
    transition: all 0.4s;
    cursor: pointer;
}

.button_yellow a span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}

.button_yellow a span:after {
    content: '>';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.button_yellow a:hover span {
    padding-right: 25px;
}

.button_yellow a:hover span:after {
    opacity: 1;
    right: 0;
}

/* @media (min-width:41rem) and (max-width:60rem) {
    .intro-container {
        height: 35rem;
    }
} */

@media (max-width:40rem) {
    
    .intro-container {
        margin: 8rem 2rem 5rem 2rem;
        width: auto;
        height: 60rem;
    }
    
    .Info_text {
        line-height: 1.5rem;
        font-weight: 300;
    }

    .Info .Info_title {
        color: #ffffff;
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    .Info .Info_intro {
        width: auto;
        font-size: 1rem;
        top: 6rem;
        p {
            font-size: 0.7rem;
        }
    }

    .button_link a {
        font-size: 0.7rem;
        padding: 0.7rem;
        /* width: 12rem; */
    }

    .button_yellow a {
        font-size: 0.7rem;
        padding: 0.7rem;
    }

    .Linkbutton {
        flex-direction: row; 
        flex-wrap: wrap;
        height: 200px;
        bottom:3rem;
    }
    
    .Linkbutton a{
        margin-right:2.5rem;
        // line-height: 4rem;
    }
    
}


