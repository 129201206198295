.FoodFoundryIntro {
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
}

.FoodFoundryIntro img {
    display: block;
    margin: 10rem auto 1rem auto; 
    width: 80%;
    height: 100%;
}


@media (max-width:40rem) {
    .FoodFoundryIntro img {
        width: 90%;
    }
}
