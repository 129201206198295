.WebSection-Container {
    margin: 6rem auto;
    width: 50rem;
    h1 {
        margin-bottom: 3rem;
        text-transform: uppercase;
    }

    img {
        width: 17rem;
        margin-bottom: 0.7rem;
    } 
    line-height: 1.3rem;
}

@media (max-width:40rem) {
    .WebSection-Container {
        width: 75%;
        h1 {
           line-height: 2.5rem;
        }
        img {
            width: 13rem;
        } 
    }
}
