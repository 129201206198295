.uiuxworkContainer{
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(30rem,1fr));
    gap: 3rem;
    justify-items: center;
    align-items: center;
    padding: 1.5rem 1.5rem;
}

@media (max-width:40rem) {
    .uiuxworkContainer {
        justify-content: center;
        align-items: center;
    }
}
