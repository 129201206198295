.royal-container {
    /* margin: 0rem 0rem 3rem 0rem; */
    box-sizing: border-box;
    line-height: 1.5rem;
}

/*
    General Layout
*/

.center {
    text-align: center;
}

.section-line {
    background-color:rgb(21, 26, 38);
    font-size: 2.5rem;
    width: 100%;
    height: 100px;
    color: white;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*
    Content
*/
.Royal-intro-container {
    position: relative;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6) ), url("./img/RoyalBotanicgardenbg.jpg");
    height: 100vh;
    width: 100%;
    background-position: center;
    background-size: cover;
}

.Royal-intro-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}

.content-container {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(auto,40rem));
    grid-template-areas: "left center right";
    gap: 5rem;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem 3rem;
}

.content-container-left {
    grid-area: left;
}

.content-container-center {
    grid-area: center;
}

@media (max-width: 40rem) {
    .content-container-center {
        padding: 0rem 1.5rem;
        h1 {
            line-height: 2rem;
        }
    }

    .content-container-left {
        padding: 0rem 1.5rem;
        h1 {
            line-height: 2rem;
        }
    }

    .content-container-right {
        padding: 0rem 1.5rem;
        h1 {
            line-height: 2rem;
        }
    }
}


.content-container-right {
    grid-area: right;
}

.muckupSection01 {
    background-image: url("./img/Mouck01.jpg");
    height: 600px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 1rem 0;
}

.muckupSection02 {
    background-image: url("./img/Mouck02.jpg");
    height: 600px;
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 1rem 0 3rem 0;
}

.one-column-container {
    //display: grid;
    //grid-template-columns: minmax(30rem,auto);
    //justify-content: center;
    //align-items: flex-start;
    padding: 3rem 3rem;
}

.two-column-container {
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(auto,60rem));
    grid-template-areas: "left right";
    gap: 5rem;
    justify-content: center;
    align-items: flex-start;
    padding: 3rem 3rem;
}

.two-column-container-left {
    grid-area: left;
}

.two-column-container-right {
    grid-area: right;
}

@media (max-width: 40rem) {

    .two-column-container-left {
        padding: 0 1.5rem;
        h1 {
            line-height: 2rem;
        }
    }

    .two-column-container-right {
        padding: 0 1.5rem;
        h1 {
            line-height: 2rem;
        }
    }
}

.marginBottom {
    margin-bottom: 2rem;
}

.limitWidth {
    width: 50rem;
}

/*
    grid-template:max-content;
    auto-fill -> auto shrink or fit
    auto-fit -> add new columns, even if they are empty
*/

/*
    just-self:end;
    align-self:baseline;
*/
.royal-container-intro {
    grid-area: into;
    /* background-color: teal; */
    height: 100vh;
    /* grid-column: 1/-1;
    grid-row: 1/-1; */
}

.survey {
    grid-area: survey;
    /* grid-column: 1/-2;
    grid-row: 2/2; */
    /* align-self: start; */
}

.persona {
    grid-area: persona;
}

.scenario {
    grid-area: scenario;
}

.wireflow {
    grid-area: wireflow;
} 

.designex1 {
    grid-area: designex1;
}

.designex2 {
    grid-area: designex2;
}

.iot {
    grid-area: iot;
}

.prototype {
    grid-area: Prototype;
}

.summary {
    grid-area: summary;
}

.nextplan {
    grid-area: nextplan;
}

.imgsize {
    /* width: 30%;
    height: 30%; */
    object-fit: cover;
}

.center {
    display: block;
    margin: 0 auto;
    /* padding: 0 5rem; */
}

.title {
    font-weight: 700;
    font-size: 2rem;
    /* margin-bottom: 3.5rem; */
}

.bottom {
    margin-bottom: 3rem;
}

.right {
    margin-right: 10rem;
}

.bold {
    font-weight: 500;
}

.ShowlinkIntro {
    text-align: center;
}

/* .player-wrapper {
    position: relative;
    padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) 
  }
   
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
} */

@media screen and (min-width: 768px) and (max-width:1380px){
    .content-container {
        /* grid-template-columns: repeat(auto-fill,minmax(25rem,auto)); */
        grid-template-areas:"left" 
                            "center" 
                            "right";
    }
    .paddingTopZero {
        padding: 0 3rem;
    }
    
    .two-column-container {
        grid-template-areas: "left"
                             "right";
    }
}

@media (max-width:50rem){
    .content-container {
        /* grid-template-columns: repeat(auto-fill,minmax(25rem,auto)); */
        grid-template-areas:"left" 
                            "center" 
                            "right";         
    }
    .paddingTopZero {
        padding: 0 3rem;
    }
    
    .two-column-container {
        grid-template-areas: "left"
                             "right";
    }
}


@media (max-width: 40rem) {
    .royal-container {
        margin-top: 0rem;
    }

    .one-column-container {
        //grid-template-columns: minmax(21rem,auto);
    }

    .content-container {
        grid-template-columns: repeat(auto-fit,minmax(21rem,auto));
        grid-template-areas: "left" 
                            "center" 
                            "right";
        gap: 3rem;
    }

    .paddingTopZero {
        padding: 0 3rem;
    }

    .two-column-container {
        grid-template-columns: repeat(auto-fit,minmax(21rem,auto));
        grid-template-areas: "left"
                             "right";
        padding: 3rem 3rem;
    }

    .survey {
        grid-area: survey;
        
    }
    .title {
        font-size: 1.2rem;
    }

    .Royal-intro-title {
        width: 360px;
        font-size: 1.5rem;
        text-align: center;
        line-height: 2rem;
    }

    .section-line {
        font-size: 1.2rem;
        text-align: center;
    }
    .ShowvideoIntro {
        display: none;
    }
}

@media (min-width: 40rem) {
    .ShowlinkIntro {
      display: none;
    }
}
