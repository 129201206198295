.ARPetIntro {
    /* height: 10em;
    position: relative  */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ARPetIntro img {
    /* height: 10em; */
    margin: 120px auto 10px auto; 
    width: 80%;
    height: 100%;
}

.videoplayer {
    margin: 15px auto 15px auto;
}

@media (max-width: 40rem) {
    .videoplayer {
        /* margin: 10px auto 100px auto; */
       height: 100px;
    }
}

@media (max-width: 40rem) {
    .ARPetIntro img {
        width: 90%;
    }
}